import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { red } from '@mui/material/colors';
import { Button, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import axios from 'axios';

import getEnvironement from '../../environnement';
import CustomSelect from '../../utils/components/inputs/customSelect';
import { userSelector } from '../../utils/store/userSlice';

function ProductData({
  label,
  data
}) {
  return (
    <div className='flex'>
      <p className='mr-3'>{label}</p>
      <p>{data}</p>
    </div>
  );
}

function ProductDocuments({documents, onClickDeleteDocument, onClickSaveDocument}) {
  const [fileName, setFileName] = useState('Ajouter un document');
  const [fileType, setFileType] = useState('reçu');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);
    } else {
      setFileName('Ajouter un document');
      setSelectedFile(null);
    }
  };

  return (
    <>
      <p>-----------------------------</p>
      <p>documents:</p>
      {documents && documents.map((document, index) => {
        console.log(document.name);
        return (
          <div key={index} className='flex items-center'>
            <Link
              className='hover:text-primary'
              to={`${getEnvironement().BACKEND_URL}/storage/pdfs/${document.file}`}
              target='_blank'
            >
              {document.name}
            </Link>
            <span className='mx-1' />
            <IconButton onClick={() => onClickDeleteDocument(document.id)}>
              <ClearIcon sx={{ color: red[500] }}/>
            </IconButton>
          </div>
        );
      })}
      <div className='flex'>
        <div style={{ position: 'relative', display: 'inline-block', overflow: 'hidden', marginRight: '8px'}}>
          <Button variant='outlined' style={{height: '40px'}}>
            {fileName}
            <input
              type="file"
              onChange={handleFileChange}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                cursor: 'pointer',
                opacity: 0,
              }}
            />
          </Button>
        </div>
        <div>
          <CustomSelect
            items={[
              {label: 'reçu', value: 'reçu'},
              {label: 'legalisé', value: 'legalisé'}
            ]}
            value={fileType}
            setValue={(value) => setFileType(value)}
          />
        </div>
        <Button
          variant='contained'
          style={{height: '38px'}}
          onClick={() => onClickSaveDocument(selectedFile, fileName, fileType)}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

function ProductStatus({
  status,
  idProduit,
  onClickSaveStatus,
  onClickDeleteStatus
}) {
  const user = useSelector(userSelector);
  const [statuses, setStatuses] = useState([{id: 'default', status: 'Ajouter un nouveau status'}]);
  const [selectedStatus, setSelectedStatus] = useState('Ajouter un nouveau status');
  const [selectedIndex, setSelectedIndex] = useState('default');

  const getStatuses = async () => {
    const params = {managerId: user.id};
    axios.get(`${getEnvironement().API_URL}/admin/status`, {params})
      .then((response) => setStatuses([{id: 'default', status: 'Ajouter un nouveau status'}, ...response.data]));
  };

  const getStatus = (statusString) => {
    if (statusString === 'Rejetée') {
      return (
        <p className='rounded-full bg-[#FDB9BC] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#F04148]'>Rejetée</p>
      );
    } else if (statusString === 'Validée') {
      return (
        <p className='rounded-full bg-[#CAFFBD] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#35DE0A]'>Validée</p>
      );
    } else {
      return (
        <p className='rounded-full bg-[#E5E5E5] font-poppins font-normal text-sm/[16px] text-center py-1 px-5 m-1 text-[#000000]'>{statusString}</p>
      );
    }
  };

  const deleteStatus = (statusId, productId) => {
    if (status.length === 1) {
      toast.error('au moins un status est obligatoire, merci d\'ajouter un autre status avant de supprimer l\'éxistant', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } else {
      onClickDeleteStatus(statusId, productId);
    }
  };

  useEffect(() => {
    getStatuses();
  }, []);
  return (
    <>
      <p>-----------------------------</p>
      <p>status:</p>
      {status && status.map((item) => {
        return (
          <div key={item.id} className='flex items-center'>
            {getStatus(item.status)}
            <IconButton onClick={() => deleteStatus(item.pivot.status_id, item.pivot.product_id)}>
              <ClearIcon sx={{ color: red[500] }}/>
            </IconButton>
          </div>
        );
      })}
      <div className='flex'>
        <CustomSelect
          items={statuses.map((item) => {
            return {label: item.status, value: item.status};
          })}
          value={selectedStatus}
          setValue={(value) => {setSelectedStatus(value); setSelectedIndex(statuses.filter((item) => item.status === value)[0].id);}}
        />
        <Button
          variant='contained'
          style={{height: '40px'}}
          onClick={() => onClickSaveStatus(selectedIndex, idProduit)}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export function CreationStatut({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination}/>
      <ProductData label='Sigle: ' data={produit?.sigle}/>
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique}/>
      <ProductData label='Ville: ' data={produit?.ville}/>
      <ProductData label='Activité commercial: ' data={produit?.activiteCommercial}/>
      <ProductData label='Soussignés: ' data={produit?.soussignes && JSON.parse(produit?.soussignes)}/>
      <ProductData label='Siège social: ' data={produit?.siegeSocial}/>
      <ProductData label='Durée de la societe: ' data={produit?.dureeSociete}/>
      <ProductData label='Objets social: ' data={produit?.objetsSocial && JSON.parse(produit?.objetsSocial)}/>
      <ProductData label='Apports: ' data={produit?.apports && JSON.parse(produit?.apports)}/>
      <ProductData label='Parts social: ' data={produit?.partsSocial && JSON.parse(produit?.partsSocial)}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function CreationEntreprise({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='type beneficiaire: ' data={produit?.typeBeneficiaire}/>
      <ProductData label='nom bénéficiaire: ' data={produit?.nomBeneficiaire}/>
      <ProductData label='prenom bénéficiaire: ' data={produit?.prenomBeneficiaire}/>
      <ProductData label='nationalité bénéficiaire: ' data={produit?.nationaliteBeneficiaire}/>
      <ProductData label='cin bénéficiaire: ' data={produit?.nationaliteBeneficiaire}/>
      <ProductData label='denomination bénéficiaire: ' data={produit?.denominationBeneficiaire}/>
      <ProductData label='ice bénéficiaire: ' data={produit?.iceBeneficiaire}/>
      <ProductData label='rc bénéficiaire: ' data={produit?.rcBeneficiaire}/>
      <ProductData label='gsm bénéficiaire: ' data={produit?.gsmBeneficiare}/>
      <ProductData label='email bénéficiaire: ' data={produit?.emailBeneficiaire}/>
      <ProductData label='adresse bénéficiaire: ' data={produit?.adresseBeneficiaire}/>
      <ProductData label='Dénomination 1: ' data={produit?.denomination1}/>
      <ProductData label='Sigle 1: ' data={produit?.sigle1}/>
      <ProductData label='Dénomination 2: ' data={produit?.denomination2}/>
      <ProductData label='Sigle 2: ' data={produit?.sigle2}/>
      <ProductData label='Dénomination 3: ' data={produit?.denomination3}/>
      <ProductData label='Sigle 3: ' data={produit?.sigle3}/>
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique}/>
      <ProductData label='Ville: ' data={produit?.ville}/>
      <ProductData label='Activité commercial: ' data={produit?.activiteCommercial}/>
      <ProductData label='Soussignés: ' data={produit?.soussignes && JSON.parse(produit?.soussignes)}/>
      <ProductData label='Siège social: ' data={produit?.siegeSocial}/>
      <ProductData label='Durée de la societe: ' data={produit?.dureeSociete}/>
      <ProductData label='Objets social: ' data={produit?.objetsSocial && JSON.parse(produit?.objetsSocial)}/>
      <ProductData label='Apports: ' data={produit?.apports && JSON.parse(produit?.apports)}/>
      <ProductData label='Parts social: ' data={produit?.partsSocial && JSON.parse(produit?.partsSocial)}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function CreationAssociation({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination}/>
      <ProductData label='Abréviation: ' data={produit?.abreviation}/>
      <ProductData label='Objets social: ' data={produit?.objetsSocial && JSON.parse(produit?.objetsSocial)}/>
      <ProductData label='Siège social: ' data={produit?.siegeSocial}/>
      <ProductData label='Membres de la fondateurs: ' data={produit?.MembresFondateurs && JSON.parse(produit?.MembresFondateurs)}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function CreationAutoEntreprise({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='type micro-entreprise: ' data={produit?.typeMicroEntreprise}/>
      <ProductData label='délai de création entreprise: ' data={produit?.delaiCreationEntreprise}/>
      <ProductData label='Déjà auto-entrepreneur: ' data={produit?.isDejaAutoEntrepreneur}/>
      <ProductData label='Siège social: ' data={produit?.siegeSocial}/>
      <ProductData label={'Domaine d\'activité: '} data={produit?.domaineActivite}/>
      <ProductData label='Plateforme coursier: ' data={produit?.plateformeCoursier}/>
      <ProductData label='Situation actuelle: ' data={produit?.situationActuelle}/>
      <ProductData label='Connaissance auto-entreprise: ' data={produit?.isConnaissanceAutoEntreprise}/>
      <ProductData label='Accompagnement création micro-entreprise: ' data={produit?.isAccompagnementCreationMicroEntreprise}/>
      <ProductData label='Genre: ' data={produit?.genre}/>
      <ProductData label='Nom: ' data={produit?.nom}/>
      <ProductData label='Prénom: ' data={produit?.prenom}/>
      <ProductData label='Adresse domicile: ' data={produit?.adresseDomicile}/>
      <ProductData label='Code postal: ' data={produit?.codePostal}/>
      <ProductData label='Ville: ' data={produit?.ville}/>
      <ProductData label='Adresse e-mail: ' data={produit?.adresseEmail}/>
      <ProductData label='Numero de téléphone: ' data={produit?.numeroTelephone}/>
      <ProductData label='Recherche: ' data={produit?.recherche}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function CarteGrise({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Nom: ' data={produit?.nom}/>
      <ProductData label='Prénom: ' data={produit?.prenom}/>
      <ProductData label='Date de naissance: ' data={produit?.dateNaissance}/>
      <ProductData label='Nationalité: ' data={produit?.nationalite}/>
      <ProductData label='Cni: ' data={produit?.cni}/>
      <ProductData label='Adresse: ' data={produit?.adresse}/>
      <ProductData label='Téléphone: ' data={produit?.tel}/>
      <ProductData label='Email: ' data={produit?.email}/>
      <ProductData label='Marque de véhicule: ' data={produit?.marqueVehicule}/>
      <ProductData label='Modele de véhicule: ' data={produit?.modeleVehicule}/>
      <ProductData label='Numero de chasis: ' data={produit?.numeroChasis}/>
      <ProductData label='Type de véhicule: ' data={produit?.typeVehicule}/>
      <ProductData label='Date de mise en circulation: ' data={produit?.dateMiseCirculation}/>
      <ProductData label='Puissance fiscale: ' data={produit?.puissanceFiscale}/>
      <ProductData label={'Numero d\'immatriculation: '} data={produit?.numeroImmatriculation}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function Cession({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination}/>
      <ProductData label='Sigle: ' data={produit?.sigle}/>
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique}/>
      <ProductData label='Registre de commerce: ' data={produit?.rc}/>
      <ProductData label='Vendeur: ' data={produit?.vendeur}/>
      <ProductData label='Acheteur: ' data={produit?.acheteur}/>
      <ProductData label='Date de cession: ' data={produit?.dateCession}/>
      <ProductData label='Montant de cession: ' data={produit?.montantCession}/>
      <ProductData label={'Déscription de l\'activité transférée: '} data={produit?.descriptionActiviteTransferee}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function Dissolution({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination}/>
      <ProductData label='Sigle: ' data={produit?.sigle}/>
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique}/>
      <ProductData label='Registre de commerce: ' data={produit?.rc}/>
      <ProductData label='Liquidateur: ' data={produit?.liquidateur}/>
      <ProductData label='Associés: ' data={produit?.associes}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}

export function ChangementStatut({
  idProduit,
  produit,
  onClickSaveStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument
}) {
  return (
    <div>
      <ProductData label='Dénomination: ' data={produit?.denomination}/>
      <ProductData label='Sigle: ' data={produit?.sigle}/>
      <ProductData label='Forme juridique: ' data={produit?.formeJuridique}/>
      <ProductData label='Registre de commerce: ' data={produit?.rc}/>
      <ProductData label='Demandeur: ' data={produit?.demandeur}/>
      <p>-----------------------------</p>
      <ProductData label='date de création: ' data={produit?.created_at}/>
      <ProductData label='date de dérnère modification:' data={produit?.updated_at}/>
      <ProductDocuments
        documents={produit?.documents}
        onClickSaveDocument={onClickSaveDocument}
        onClickDeleteDocument={onClickDeleteDocument}
      />
      <ProductStatus
        status={produit?.status}
        idProduit={idProduit}
        onClickSaveStatus={onClickSaveStatus}
        onClickDeleteStatus={onClickDeleteStatus}
      />
    </div>
  );
}
